export function renderChanges(){
	let informerEl   = $('.header-cart-popup');
	let cartEl       = $('.cart');
	let buttonEl   	 = $('.header-cart');
	let addButtonEls = $('[data-cart-add-btn]');
	let giftEls 		 = $('[data-gift]');

	// get changes
	$.ajax({
		url: data.ajax_url,
		dataType: 'json',
		data: {
			action: 'cart_get_changes'
		}
	})
	.done(function(response){
		if(response.button && response.informer) {
			informerEl.html(response.informer).removeClass('header-cart-popup--loading');
			cartEl.html(response.cart);
			buttonEl.html(response.button);

			// make add to cart buttons active
			for(let item in response.items) {
				addButtonEls.filter('[data-id="' + item + '"]').addClass('active');

				addButtonEls = addButtonEls.filter(':not([data-id="' + item + '"])');
				addButtonEls = addButtonEls.filter(':not([data-product-id="' + item + '"])');

				// gift items 
				giftEls.filter('[data-product-id="' + item + '"]').addClass('active');
				giftEls = giftEls.filter(':not([data-product-id="' + item + '"])');
			}
			addButtonEls.removeClass('active');

		}
		else {
			console.error('Не удалось обновить корзину в шапке');
		}
	})
	.fail(function(error){
		console.error(error);
		alert('Непредвиденная ошибка');
	});
}
