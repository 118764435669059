import Swiper from 'swiper';

let sliderEls = $('.images-carousel');

for(var i = 0; i < sliderEls.length; i++) {
	let wrapperEl = $(sliderEls[i]);
  let swiperEl  = wrapperEl.find('.swiper-container');
  let slideEls  = wrapperEl.find('.swiper-slide');
	let prevEl 		= wrapperEl.find('.carousel-prev');
	let nextEl 		= wrapperEl.find('.carousel-next');

  let options = {
    slidesPerView: 5,
		navigation: {
    	prevEl: prevEl[0],
			nextEl: nextEl[0]
		},
		breakpoints: {
			1150: {
				slidesPerView: 2
			},
			768: {
				slidesPerView: 1
			}
		}
  };

  // different options based on slide count
  if(slideEls.length > 5) {
    options.loop = true;
    options.centeredSlides = true;
  }
  else {
    options.centerInsufficientSlides = true;
  }

  // initialize
	new Swiper(swiperEl[0], options);
}