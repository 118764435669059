import ProgressBar from 'progressbar.js';

var progressEls = $('.bequick-gifts__progress');

for(var i = 0; i < progressEls.length; i++) {
	var progressEl = progressEls[i];

	var progress = new ProgressBar.Circle(progressEl, {
		strokeWidth: 4,
		trailColor: '#a2c9c5',
		color: '#40968d'
	});

	progress.animate(+$(progressEl).attr('data-value'));
}