let expandEls = $('.map-delivery-days__tbody .map-delivery-days__td');

expandEls.on('click', function(){
	let thisEl 			= $(this);
	let rowEl 			= thisEl.closest('.map-delivery-days__row');
	let detailsEl  	= rowEl.find('.map-delivery-days__details'); 

	// only mobile
	if(window.innerWidth < 768) {
		// toggle city block active class
		rowEl.toggleClass('expanded');
	
		// toggle details
		detailsEl.slideToggle();
	}
});