import 'slick-carousel';



// import shared
import './shared/aos';
import './shared/ios-scroll';
import './shared/popup';
import './shared/mask';
import './shared/scroll-to';

// import retailcrm
import './retailcrm/modals';
import './retailcrm/set-utm-marks';

// import header js
import './header/cart';
import './header/search';
import './header/catalog';
import './header/accessory';
import './header/mobile-menu';
import './header/scroll-to';
import './header/fixed';
import './header/fixed-mobile';

// import discount js
import './discount/slider';
import './discount/circle-progress';

// import cart js
import './cart/cart';

// import footer menu
import './footer/footer';

// import promo-products
import './promo-products/select-product';
import './promo-products/slider';

// import block-products
import './block-products/slider';

// import block-complects
import './block-complects/slider';

// import images-carousel
import './images-carousel/slider';
import './images-carousel/zoom';

// import reviews
import './reviews/slider';
import './reviews/tabs';

// import how-works
import './how-works/hover';

// import map-delivery
import './map-delivery/animate-dots';
import './map-delivery/info';
import './map-delivery/details';
import './map-delivery/scroll-to-city';
import './map-delivery/points-expand';
import './map-delivery/days-expand';
import './map-delivery/block-info-match-height';
import './map-delivery/stick-thead';

// import production-photos
import './production-photos/adjust-rows';

// import product
import './product/slider';
import './product/zoom';

// import gifts
import './gifts/slider';
import './gifts/details';

// import catalog-products
import './catalog-products/details';
import './catalog-products/match-height';

// import catalog
import './catalog/match-height';
import './catalog/sort';
import './catalog/more';



// old modals file
$(function () {
	$.fn.tabs = function (opts) {
		var conf = $.extend({ cb: $.noop }, opts);
		var $tabLinks = $('a', this);

		$tabLinks.on('click', function (e) {
			e.preventDefault();
			var $el = $(this);
			var $old = $tabLinks.filter('.active');

			var oldSelector = $old.data('tab');
			$('.' + oldSelector).hide();

			var currentClass = $el.data('tab');
			$('.' + currentClass).show();

			$old.removeClass('active');
			$el.addClass('active');

			conf.cb(currentClass);
		});

		$tabLinks.filter('.active').click();
	};

	// tabs in calculator
	$('.js-calc-tabs').tabs({
		cb: function cb(currentVal) {

			var $imagePreview = $('.js-calc-preview');

			if (currentVal === 'js-tab-content-brand') {
			    if ( 1>selected_model )
				    $imagePreview.attr('src', $imagePreview.data('src-brand'));
			}
			/*else {
				$imagePreview.attr('src', $imagePreview.data('src-size'));
			}*/
		}
	});

	// simple tabs
	//$('.js-tabs').tabs();

	// quantity
	$('.js-quantity').each(function () {
		var $elVal = $('.js-quantity-val', this);
		var quantity = parseInt($elVal.text());

		$('.js-quantity-dec', this).on('click', function (e) {
			e.preventDefault();
			quantity = Math.max(1, --quantity);
			$elVal.text(quantity);
		});

		$('.js-quantity-inc', this).on('click', function (e) {
			e.preventDefault();
			quantity = Math.min(99, ++quantity);
			$elVal.text(quantity);
		});
	});

	$('.js-popup-action').on('click', function (e) {
		e.preventDefault();
		$('.popup:visible').hide();
		var id = $(this).data('popup-id');
		var $popup = $('#' + id);

		$popup.show();

		$popup.css('top', $(window).scrollTop() + 100);

		$('.js-popup-blackout').show();

		action('popup shown',id);

	});

	$('.js-popup-blackout, .js-popup-close').on('click', function (e) {
		e.preventDefault();
		$('.popup:visible').hide();
		$('.js-popup-blackout').hide();
	});

	$('.js-certificates-slider').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		nextArrow: '.js-certificates-next',
		prevArrow: '.js-certificates-prev'
	});

	$('.js-clients-slider').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		nextArrow: '.js-clients-next',
		prevArrow: '.js-clients-prev'
	});

	$('.js-dropdown').each(function () {
		var el = this;
		var $el = $(this);
		var $link = $('.js-dropdown-link', this);
		var $content = $('.js-dropdown-options', this);

		$link.on('click', function (e) {
			e.preventDefault();
			$content.toggle();
		});

		$(window).on('click', function (e) {
			if ($(e.target).closest(el).length === 0) {
				$content.hide();
			}
		});
	});

	$('.js-pickup-toggle').on('click', function (e) {
		e.preventDefault();
		$(this).closest('.pickup-point').toggleClass('closed');
	});

	$('.js-delivery-marker-link').on('mouseenter', function () {
		$('.js-delivery-marker-details').hide();
		$(this).next('.js-delivery-marker-details').show();
	});

	$('.js-deliver-marker').on('mouseleave', function () {
		$('.js-delivery-marker-details', this).hide();
	});

	// popup gallery
	$('.js-popup-galery .previews a').bind('click', function (e) {
		e.preventDefault();
		$('.js-popup-galery .previews a').removeClass('active');
		$(this).addClass('active');
		$('.js-popup-galery .large img').attr('src', $(this).attr('href'));
	});
});