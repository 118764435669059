import { renderChanges } from './renderChanges.js';

export function removeItem(productId){
	// add loader
	$('.cart-table').addClass('cart-table--loading');
	$('.header-cart-popup').addClass('header-cart-popup--loading');

	// remove item
	$.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		data: {
			action: 'cart_remove_item',
			product_id: productId
		}
	})
	.done(function(response){
		// if error
		if(response.error) {
			console.error(response);
			alert('Непредвиденная ошибка');
		}
		// update cart header
		else if(response.success) {
			renderChanges();

			// update buttons
			let buttonEls = $('[data-cart-add-btn][data-product-id="' + productId + '"]');
			buttonEls.removeClass('active');

			// update gift items
			let giftEls = $('[data-gift][data-product-id="' + productId + '"]');
			giftEls.removeClass('active');

			// update gift details
			let giftDetailsEl = $('[data-gift-details][data-product-id="' + productId + '"]');
			giftDetailsEl.removeClass('active');
		}
	})
	.fail(function(error){
		console.error(error);
		alert('Непредвиденная ошибка');
	});
}