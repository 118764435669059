import { renderChanges } from './renderChanges.js';

export function changeAmount(productId, amount){
	// add loader
	$('.cart-table').addClass('cart-table--loading');
	$('.header-cart-popup').addClass('header-cart-popup--loading');

	// change amount
	$.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		data: {
			action: 'cart_change_amount',
			product_id: productId,
			amount: amount
		}
	})
	.done(function(response){
		// if error
		if(response.error) {
			console.error(response);
			alert('Непредвиденная ошибка');
		}
		// update cart header
		else if(response.success) {
			renderChanges();
		}
	})
	.fail(function(error){
		console.error(error);
		alert('Непредвиденная ошибка');
	});
}