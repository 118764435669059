'use strict'


$(document).ready(function () {

    // Прокрутка вверх
    $(window).scroll(function () {
        let scr_top = $(window).scrollTop();
        scr_top > 100 ? $('.js-up').fadeIn(300) : $('.js-up').fadeOut(300);
    });
    $('.js-up').click(function () {
        $('html, boud').animate({ scrollTop: 0 }, 300);
    });

    // боковое меню

    $('.js-side-menu-mob').click(function () {
        $('.js-side-menu').toggleClass('active');
        $(this).toggleClass('no-active');
    });

    $('.side-menu__close').click(function () {
        $('.js-side-menu').removeClass('active');
        $('.js-side-menu-mob').removeClass('no-active');
    });
	
});