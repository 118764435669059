var CatalogLoadMore = {
	ajax: null,
	onButtonClick: function(event)
	{
		event.preventDefault();

		let $button = $(this);

		let page = $button.attr('data-page');

		if(page == null || page < 2)
			return;
		
		let order = $button.attr('data-order');
		let sort = $button.attr('data-sort');
		let is_page = $button.attr('data-is_page');
		let category = $button.attr('data-category');

		if(CatalogLoadMore.ajax != null)
			return;

		let formData = new FormData();
		formData.append('action', 'load_more_catalog');
		formData.append('page', page);
		if(order != null) formData.append('order', order);
		if(sort != null) formData.append('sort', sort);
		if(is_page != null) formData.append('is_page', is_page);
		if(category != null) formData.append('category', category);

		$button.css('display', 'none');

		CatalogLoadMore.ajax = $.ajax
		(
			{
				url: window.data.ajax_url,
				type: 'POST',
				data: formData,
				processData: false,
				contentType: false,
                dataType: 'json',
                async: true,
				success: function(data)
				{
                    if(data.result == 'ok')
                    {
						if(data.more)
							$button.css('display', 'unset');

						$('.js-catalog-items').append(data.html);

						$button.attr('data-page', data.page);
						history.pushState(null, '', data.link);

						$('.catalog-pagination').html(data.pagination);
					}
					CatalogLoadMore.ajax = null;
				},
				error: function(param1, param2, param3)
				{
					console.log(param1);
					console.log(param2);
					console.log(param3);
					CatalogLoadMore.ajax = null;
				}
			}	
		);

	},
	init: function() {
		$('.js-catalog-loadmore').click(this.onButtonClick);
	}	
};

CatalogLoadMore.init();