// catalog
var catalogEl 		 		= $('.header-catalog');
var catalogButtonEl 	= $('.header-menu__item--catalog');
var catalogItemEl  		= $('.header-catalog__item a');
var catalogImageEl 		= $('.header-catalog__image img');

// accessory
var accessoryEl    		= $('.header-accessory');
var accessoryButtonEl = $('.header-menu__item--accessory');

// set mouse inside catalog or outside
var insideCatalog = false;
catalogEl.on('mouseenter', function(){ insideCatalog = true  });
catalogEl.on('mouseleave', function(){ insideCatalog = false });

// show and hide catalog
catalogButtonEl.on('mouseenter', function(){

	// enable catalog
	catalogEl.fadeIn();

	// disable accessory
	accessoryEl.hide();
	accessoryButtonEl.removeClass('active');

	// add active to button
	catalogButtonEl.addClass('active');

}).on('mouseleave', function(){

	// hide catalog if mouse not inside after 200ms
	setTimeout(function(){
		if(!insideCatalog) {
			catalogEl.hide();
			catalogButtonEl.removeClass('active');

			// remove active to button
			catalogButtonEl.removeClass('active');
		}
	}, 200);

});

// hide header catalog on mouseleave 
catalogEl.on('mouseleave', function(){
	
	// hide catalog if mouse not inside after 200ms
	setTimeout(function(){
		if(!insideCatalog) {
			catalogEl.hide();

			// remove active to button
			catalogButtonEl.removeClass('active');
		}
	}, 200);

});

// show image on hover
catalogItemEl.on('mouseenter', function(){
	var thisEl = $(this);

	// add active to current item
	catalogItemEl.removeClass('active');
	thisEl.addClass('active');

	// change image
	catalogImageEl.attr('src', thisEl.attr('data-image'));
});