let itemEls = $('.header-menu__item[data-scroll-to]');

itemEls.on('click', function(){
	let thisEl = $(this);
	let targetEl = $(thisEl.attr('data-scroll-to'));

	if(targetEl.length > 0) {
		$('body, html').animate({
			scrollTop: targetEl.offset().top - 20
		}, 1000);
	}
});