
$(window).on('resize', function(){
  adjustContainer();
});

adjustContainer();  

function adjustContainer(){
  let containerEl = $('.production-photos__items');
  let width = containerEl.innerWidth();
  let gap   = parseInt(containerEl.css('grid-gap'));
  
  containerEl.css('grid-auto-rows', (width - gap * 5) / 12);
}