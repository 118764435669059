import initSliders from '../product/slider';

// on checkbox select
$('.promo-product').on('click', function () {
  let $this           = $(this),
    wrapperEl         = $this.closest('.promo-products'),
    row_id            = $this.attr('data-id'),
    all_rows          = wrapperEl.find('.promo-product'),
    all_rows_content  = wrapperEl.find('.promo-products__content'),
    content_id        = all_rows_content.find('.promo-content__block[data-id="' + row_id + '"]');

  let block_price = wrapperEl.find('.promo-products__price'),
    current_price = $this.find('.promo-product__price');


  all_rows.removeClass('active');
  all_rows_content.find('.promo-content__block').removeClass('active');

  $this.addClass('active');
  content_id.addClass('active');

  let main_gallery = content_id.find('.product-child-main-gallery');

  main_gallery.show();
  main_gallery.addClass('active');

  main_gallery.find('.gallery-items').slick('setPosition');
  main_gallery.find('.gallery-items').slick('slickGoTo', 0);

  // Prices
  block_price.html(current_price.html());


  // Show modal order button connected to this product
  let modalBtnEls = wrapperEl.find('.promo-products__quick-order');
  modalBtnEls.hide();
  modalBtnEls.filter('[data-id="' + row_id + '"]').show();

  // Show in_cart button
  let orderBtnEls = wrapperEl.find('.promo-products__buy');
  orderBtnEls.hide();
  orderBtnEls.filter('[data-id="' + row_id + '"]').show();

  // Show images
  let imagesEls = wrapperEl.find('.product-images');
  imagesEls.hide();
  let imagesEl = imagesEls.filter('[data-id="' + row_id  +  '"]').show();
  initSliders(imagesEl[0]);
});