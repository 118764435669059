let headerEl = document.querySelector('.header');
let fixedEl = document.querySelector('.header-fixed-mobile');
let wait = false;
// 0 - fully hide
// 1 - fully visible
let currentMode = 0;

// works only on desktop
if (window.innerWidth < 768) {
    // show on init
    fixedEl.style.display = '';
    fixedEl.style.transform = 'translateY(-' + (fixedEl.offsetHeight + 40) + 'px)';
    scrolled();

    window.addEventListener('scroll', function() {
        scrolled();
    });

    function scrolled() {
        if (!wait) {
            // scrolled past header
            if (window.scrollY > (headerEl.offsetHeight + 20)) {
                document.querySelector('.js-side-menu-mob').classList.add('mob-active');
                if (currentMode != 1) {
                    fixedSlide(1);

                }
            }
            // scrolled to header
            else {
                document.querySelector('.js-side-menu-mob').classList.remove('mob-active');
                if (currentMode != 0) {
                    fixedSlide(0);
                }
            }

            prevY = window.scrollY;
        }
    }

    function fixedSlide(mode, animateTime = 500) {
        wait = true;

        // set offset top value
        let top;
        if (mode == 0) top = -fixedEl.offsetHeight - 40;
        if (mode == 1) top = 0;

        $(fixedEl).animate({ now: top }, {
            step: function(now, fx) {
                $(this).css('transform', 'translateY(' + now + 'px)');
            },
            done: function() {
                wait = false;

                currentMode = mode;
            }
        }, animateTime);

        // overflow hidden
        if (mode == 0) fixedEl.style.overflow = 'hidden';
        else fixedEl.style.overflow = '';
    }
}