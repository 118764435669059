import 'jquery-match-height';

let titleEls = $('.catalog-item__title');

titleEls.matchHeight();

$('.js-bnt-mob-catalog').click(function(e) {
    e.preventDefault();
    $('.catalog__sidebar').addClass('active');
}); 

$('.js-close-mob-catalog').click(function(e) {
    $('.catalog__sidebar').removeClass('active');
}); 

