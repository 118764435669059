import '../../libs/jquery.masked-input/jquery.maskedinput';

function filter_keypress(e, regexp) {
  e=e || window.event;
  var target=e.target || e.srcElement;
  var code= (e.keyCode == 0) ? e.which : e.keyCode;

  if (target.tagName.toUpperCase()=='INPUT')
  {
    //if (code<32 || e.ctrlKey || e.altKey) return true;
    // Разрешаем нажатие клавиш backspace, Del, Tab и Esc
    if ( code == 46 || code == 8 || code == 9 || code == 27 ||
      // Разрешаем выделение: Ctrl+A
      (code == 65 && e.ctrlKey === true) ||
      // Разрешаем клавиши навигации: Home, End, Left, Right
      (code >= 35 && code <= 39) ) {
      return true;
    } 
    else {
      var char = String.fromCharCode(code);
      if (!regexp.test(char)) return false;
    }
  }
  return true;
}

function filter_phone(event_obj) {
    var phoneRegexSymbols = /[0-9\+\- ]/;
    return filter_keypress(event_obj, phoneRegexSymbols);
}

function filter_email(event_obj) {
  var emailRegexSymbols = /[A-Za-z0-9А-Яа-я\.@-_]/;
  return filter_keypress(event_obj, emailRegexSymbols);
}

String.prototype.capitalize = function() {
    return this.replace(/(?:^|\s|\-)\S/g, function(a) { return a.toUpperCase(); });
};

let charmap = {
    '`' : 'ё', '~' : 'Ё', 'q' : 'й', 'w' : 'ц', 'e' : 'у', 'r' : 'к', 't' : 'е', 'y' : 'н', 'u' : 'г',
    'i' : 'ш', 'o' : 'щ', 'p' : 'з', '[' : 'х', ']' : 'ъ', 'a' : 'ф', 's' : 'ы', 'd' : 'в', 'f' : 'а',
    'g' : 'п', 'h' : 'р', 'j' : 'о', 'k' : 'л', 'l' : 'д', ';' : 'ж', '\'' : 'э', 'z' : 'я', 'x' : 'ч',
    'c' : 'с', 'v' : 'м', 'b' : 'и', 'n' : 'т', 'm' : 'ь', ',' : 'б', '.' : 'ю','Q' : 'Й', 'W' : 'Ц',
    'E' : 'У', 'R' : 'К', 'T' : 'Е', 'Y' : 'Н', 'U' : 'Г', 'I' : 'Ш', 'O' : 'Щ', 'P' : 'З', '{' : 'Х',
    '}' : 'Ъ', 'A' : 'Ф', 'S' : 'Ы', 'D' : 'В', 'F' : 'А', 'G' : 'П', 'H' : 'Р', 'J' : 'О', 'K' : 'Л',
    'L' : 'Д', ':' : 'Ж', '"' : 'Э', 'Z' : 'Я', 'X' : 'Ч', 'C' : 'С', 'V' : 'М', 'B' : 'И', 'N' : 'Т',
    'M' : 'Ь', '<' : 'Б', '>' : 'Ю'
};

function filter_human_name(event_obj) {
  var e = event_obj;

  if(!e)e=window.event;
  var k = e.key;
  if(!k)k=String.fromCharCode(e.keyCode);
  if (undefined !== charmap[k]) k = charmap[k];

  var el = e.target;

  if('number'==typeof el.selectionStart){
      var v = el.value;
      var sel1 = el.selectionStart;
      el.value = el.value.substr(0,el.selectionStart) + k + el.value.substr(el.selectionEnd);
      el.value = el.value.toLowerCase().replace(/[^а-я\s\-]/,'').capitalize();
      el.selectionStart=el.selectionEnd=sel1+k.length;
  } else {
      // выделение в IE не работает, так что просто добавляем в конец
      el.value += k;
  }

  // что-то из этого не нужно.
  if(e.preventDefault) e.preventDefault();
  if(e.stopPropagation) e.stopPropagation();
  e.cancelBubble = true;
  return false;
}

function normalize_human_name(event_obj) {
    var e = event_obj;
    if(!e)e=window.event;
    var el = e.target;
    var sel1 = el.selectionStart;
    var sel2 = el.selectionEnd;

    var v = el.value;
    var res = '';
    for (var i=0; i<v.length; i++) {
        if (v[i].match(/[а-яА-яёЁ\s\-]/))
            res += v[i];
        else if (undefined !== charmap[v[i]])
            res += charmap[v[i]]
    }
    el.value = res.toLowerCase().capitalize();

    if('number'==typeof el.selectionStart){
        el.selectionStart=sel1;
        el.selectionEnd=sel2;
    }

    if(e.preventDefault)e.preventDefault();
    if(e.stopPropagation)e.stopPropagation();
    e.cancelBubble = true;
    return false;

}

function clear_human_name(event_obj) {
  var e = event_obj;
  if(!e)e=window.event;
  var el = e.target;

  var v = el.value;
  var res = v;

  while ((res.length>0)&&(res.match(/\s\s/))) res = res.replace(/\s\s/,' ');
  while ((res.length>0)&&(res.match(/\-\-/))) res = res.replace(/\-\-/,'-');
  while ((res.length>0)&&(res.match(/\-\s/))) res = res.replace(/\-\s/,'-');
  while ((res.length>0)&&(res.match(/\s\-/))) res = res.replace(/\s\-/,'-');
  while ((res.length>0)&&((res[0]==' ')||(res[0]=='-'))) res = res.slice(1);
  while ((res.length>0)&&((res[res.length-1]==' ')||(res[res.length-1]=='-'))) res = res.substr(0,res.length-1);

  el.value = res.toLowerCase().capitalize();

  if(e.preventDefault)e.preventDefault();
  if(e.stopPropagation)e.stopPropagation();
  e.cancelBubble = true;

  jQuery(function($){
    $('input[name=name]').each(function(){ $(this).val(el.value) } );
  });

  return false;
}

// functions to safely reinitialize
let funcNameKeypress  = function(e){ return filter_human_name(e) };
let funcNameInput     = function(e){ return normalize_human_name(e) };
let funcNameBlur      = function(e){ return clear_human_name(e) };
let funcPhoneKeypress = function(e){ return filter_phone(e) };
let funcPhoneBlur     = function(e){ let el = e.target; $('input[name=phone]').each(function(){ $(this).val(el.value) } ); };
let funcEmailKeypress = function(e){ return filter_email(e) };
let funcEmailBlur     = function(e){ let el = e.target; $('input[name=email]').each(function(){ $(this).val(el.value) } ); }

// initialize events
export function initEvents(){
  $('input[name=name]').off('keypress', funcNameKeypress).on('keypress', funcNameKeypress);
  $('input[name=name]').off('input', funcNameInput).on('input', funcNameInput);
  $('input[name=name]').off('blur', funcNameBlur).on('blur', funcNameBlur);

  $('input[name=phone]').off('keypress', funcPhoneKeypress).on('keypress', funcPhoneKeypress);
  $('input[name=phone]').off('blur', funcPhoneBlur).on('blur', funcPhoneBlur);

  $('input[name=email]').off('keypress', funcEmailKeypress).on('keypress', funcEmailKeypress);
  $('input[name=email]').off('blur', funcEmailBlur).on('blur', funcEmailBlur);
}

// initialize masks
export function initMasks(){
  $("input[name=phone]").mask("+375 (99) 999-99-99");
}

$(function(){
  initMasks();
  initEvents();
});