import { initializeReviewsSliders } from './slider';
let tabEls = $('.reviews__tab');

tabEls.on('click', function(){
	let thisEl 		= $(this);
	let wrapperEl = thisEl.closest('.reviews');
	let panelEls  = wrapperEl.find('.reviews__panel');
	let tabEls    = wrapperEl.find(".reviews__tab");

	// skip if already active
	if(thisEl.is('.active')) return;

	// deativate all panels and tabs
	tabEls.removeClass('active');
	panelEls.hide();

	// activate current panel and tab
	thisEl.addClass('active');
	panelEls.filter('[data-panel=' + thisEl.attr('data-panel') + ']').fadeIn();

	// reinit sliders
	initializeReviewsSliders();
});