import Swiper from 'swiper';

let swiperEls = $('.discount-slider .swiper-container');

for(let i = 0; i < swiperEls.length; i++) {
	let swiperEl = swiperEls[i];
	let swiper = new Swiper(swiperEl, {
		navigation: {
	    nextEl: swiperEl.querySelector('.swiper-button-next'),
	    prevEl: swiperEl.querySelector('.swiper-button-prev')
	  }
	});

	// on change show current videos
	swiper.on('slideChangeTransitionEnd', function(){
		// get index of medias
		let slideActiveEl = swiperEl.querySelector('.swiper-slide-active');
		let index = +slideActiveEl.getAttribute('data-index');
		
		// hide all medias
		let wrapperEl = swiperEl.closest('.discount');
		let mediasEls = wrapperEl.querySelectorAll('.discount-videos');
		if(mediasEls.length < 1) return;
		mediasEls.forEach(item => item.style = 'display: none');
		
		// show current medias
		let mediasEl   = wrapperEl.querySelector(".discount-videos[data-index='" + index + "']");
		if(mediasEl) mediasEl.style = '';
	});
}
