import Swiper from 'swiper';
import productImagesZoom from './zoom';

// product-top
let wrapperEls = document.querySelectorAll('.product .product-images');
wrapperEls.forEach((item) => initSliders(item));

export default function initSliders(wrapperEl, imagesCallback = false, thumbnailsCallback = false) {
  let imagesSliderEl     = wrapperEl.querySelector('.product-images__images');
  let thumbnailsSliderEl = wrapperEl.querySelector('.product-images__thumbnails');

  // imagesSlider
  let imagesSlider;
  if(imagesSliderEl) {
    let imagesSwiperEl = wrapperEl.querySelector('.product-images__images .swiper-container');
    
    // if reinit destroy previous instance
    if(imagesSwiperEl.swiper) imagesSwiperEl.swiper.destroy();

    // arrows
    let imagesPrevEl = imagesSliderEl.querySelector('.swiper-button-prev');
    let imagesNextEl = imagesSliderEl.querySelector('.swiper-button-next');

    // initialize
    imagesSlider   = new Swiper(imagesSwiperEl, {
      slidesPerView: 1,
      navigation: {
        prevEl: imagesPrevEl,
        nextEl: imagesNextEl
      }
    });

    // zoom button src change
    let zoomEl = wrapperEl.querySelector('.product-images__zoom');
    imagesSlider.on('slideChangeTransitionEnd', function(){
      let activeEl = imagesSliderEl.querySelector('.swiper-slide-active .product-images__image');

      zoomEl.setAttribute('href', activeEl.getAttribute('href'));
    });


    imagesSlider.on('init', imagesCallback);
  }

  // thumbnailsSlider
  let thumbnailsSlider;
  if(thumbnailsSliderEl) {
    let thumbnailsSwiperEl = wrapperEl.querySelector('.product-images__thumbnails .swiper-container');
    let thumbnailsPrevEl 	 = wrapperEl.querySelector('.product-images__thumbnails .swiper-button-prev');
    let thumbnailsNextEl 	 = wrapperEl.querySelector('.product-images__thumbnails .swiper-button-next');
    thumbnailsSlider       = new Swiper(thumbnailsSwiperEl, {
      slidesPerView: 4,
      spaceBetween: 10,
      loop: false,
      navigation: {
        prevEl: thumbnailsPrevEl,
        nextEl: thumbnailsNextEl
      },
      breakpoints: {
        992: {
          slidesPerView: 3
        }
      },
    });

    thumbnailsSlider.on('init', thumbnailsCallback);
  }

  // connect imagesSlider with thumbnailsSlider
  if(imagesSliderEl && thumbnailsSliderEl) {
    let thumbnailEls = wrapperEl.querySelectorAll('.product-images__thumbnail');
    
    // connect thumbnails with main slider
    thumbnailsSlider.on('slideChange', function() {
      let index = +thumbnailsSlider.realIndex;
  
      imagesSlider.slideToLoop(index);
  
      // add active to thumbnail
      let thumbEl = thumbnailsSliderEl.querySelector('.swiper-slide-active .product-images__thumbnail');
      thumbnailEls.forEach((item) => item.classList.remove('active'));
      if(thumbEl) thumbEl.classList.add('active');
    });
  
    imagesSlider.on('slideChange', function(){
      let index = +imagesSlider.realIndex;
  
      // sync thumbnails
      thumbnailsSlider.slideToLoop(index);

      // add active class to thumbnail
      let thumbEl = Array.from(thumbnailEls).find(item => item.getAttribute('data-index') == index);
      Array.from(thumbnailEls).forEach((item) => item.classList.remove('active'));
      thumbEl.classList.add('active');
    });
  
    // slide to image on click
    thumbnailEls.forEach((thumbEl) => thumbEl.onclick = () => {
      let index = +thumbEl.getAttribute('data-index');
  
      // add active class
      thumbnailEls.forEach((item) => item.classList.remove('active'));
      thumbEl.classList.add('active');
  
      // slide to thumb
      thumbnailsSlider.slideToLoop(index);
      imagesSlider.slideToLoop(index);
    });
  }
}