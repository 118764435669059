let scrollEl = document.querySelector('.map-delivery-days .popup__scroll');

scrollEl.addEventListener('scroll', function() {
  let tableEl = document.querySelector('.map-delivery-days__table');
  let tableHeadFixedEl = tableEl.querySelector('.map-delivery-days__thead.fixed');

  if(!tableEl || !tableHeadFixedEl) {
    console.error('No table or thead fixed');
    return;
  }

  // if scrolled past table header
  if(this.scrollTop > tableEl.offsetTop) {
    tableHeadFixedEl.classList.add('active');
    tableHeadFixedEl.style.display = 'block';
    tableHeadFixedEl.style.transform = 'translateY(' + (this.scrollTop - tableEl.offsetTop) + 'px)';
  }
  else {
    tableHeadFixedEl.classList.remove('active');
    tableHeadFixedEl.style.display = 'none';
  }
});