import { perfectScrollbars } from '../shared/ios-scroll';

// accessory
var accessoryEls = $('.header-accessory');
var accessoryButtonEls = $('.header-menu__item--accessory');

// accessory
var catalogEls    		= $('.header-catalog');
var catalogButtonEls = $('.header-menu__item--catalog');

// set mouse inside accessory or outside
var insideAccessory = false;
accessoryEls.on('mouseenter', function(){ insideAccessory = true  });
accessoryEls.on('mouseleave', function(){ insideAccessory = false });

// show and hide catalog
accessoryButtonEls.on('mouseenter', function(){
	// enable accessory
	accessoryEls.fadeIn();

	// show perfect scrollbars
	perfectScrollbars.forEach(( scrollbar => scrollbar.update() ));

	// disable catalog
	catalogEls.hide();
	catalogButtonEls.removeClass('active');

	// add active to button
	accessoryButtonEls.addClass('active');

}).on('mouseleave', function(){

	// hide accessory if mouse not inside after 200ms
	setTimeout(function(){
		if(!insideAccessory) {
			accessoryEls.hide();

			// remove active to button
			accessoryButtonEls.removeClass('active');
		}
	}, 200);

});

// hide header accessory on mouseleave 
accessoryEls.on('mouseleave', function(){
	
	// hide accessory if mouse not inside after 200ms
	setTimeout(function(){
		if(!insideAccessory) {
			accessoryEls.hide();

			// remove active to button
			accessoryButtonEls.removeClass('active');
		}
	}, 200);

});


// show categories and subcategories on hover
let blockLinkEls    	 = $('.header-accessory__block [data-block]');
let blockEls        	 = $('.header-accessory__items[data-block]');
let categoryLinkEls = $('.header-accessory__item [data-category]');
let categoryEls     = $('.header-accessory__items[data-category]');

// show category items on hoverX
blockLinkEls.on('mouseenter', function(){
	let thisEl = $(this);
	if(thisEl.is('.active')) return;
	
	// make category active
	blockLinkEls.removeClass('active');
	thisEl.addClass('active');
	
	// show category items
	let blockEl = blockEls.filter('[data-block=' + thisEl.attr('data-block') + ']'); 
	blockEls.hide();
	blockEl.show();
	
	// deactivate category links and hide categories
	categoryLinkEls.removeClass('active');
	categoryEls.hide();
	
	// show first category
	let blockCategoryLinkEls = blockEl.find('.header-accessory__item [data-category]');
	for(let i = 0; i < blockCategoryLinkEls.length; i++) {
		let categoryId = $(blockCategoryLinkEls[i]).attr('data-category');
		let firstCategoryLinkEls = $('.header-accessory__item [data-category="' + categoryId + '"]');
		let firstCategoryEls     = $('.header-accessory__items[data-category="' + categoryId + '"]');
		
		// if there subcategories in category show them and break foreach
		if(firstCategoryEls.length) {
			firstCategoryLinkEls.addClass('active');
			firstCategoryEls.show();

			break;
		}
	}

	// show perfect scrollbars
	perfectScrollbars.forEach(( scrollbar => scrollbar.update() ));
});

// show categories items on hover
categoryLinkEls.on('mouseenter', function(){
	let thisEl   = $(this);
	if(thisEl.is('.active')) return;
	
	let targetCategoryEl = categoryEls.filter('[data-category=' + thisEl.attr('data-category') + ']');

	// make category active
	categoryLinkEls.removeClass('active');
	thisEl.addClass('active');
	
	// show category items
	categoryEls.hide();
	if(targetCategoryEl.length) {
		targetCategoryEl.show();
	}

	// show perfect scrollbars
	perfectScrollbars.forEach(( scrollbar => scrollbar.update() ));
});


// prevent body scroll inside items list
let currentScrollEl = false;
let scrollEls = $('.header-accessory__scroll');

window.addEventListener('mousewheel', function(e) { 
	if(currentScrollEl) e.preventDefault();
}, { passive: false });

scrollEls.on('mouseenter', function() { currentScrollEl = this; });
scrollEls.on('mouseleave', function() { currentScrollEl = false; });