let params = new URLSearchParams(location.search);

$.ajax({
  url: window.data.ajax_url,
  type: 'get',
  dataType: 'text',
  data: {
    action: 'retailcrm_set_utm_marks',
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content')
  },
  error: function (xhr, errorMsg, errorObj) {
    // show error message
    console.log('Ошибка при установке меток:', errorMsg, errorObj);
    return;
  }
});