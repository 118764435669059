$('[data-popup-btn]').on('click', function(){
  var popupEl = $('[data-popup=' + $(this).attr('data-popup-btn') + ']');

  popupShow(popupEl);
});


// hide on click outside
$('[data-popup]').on('click', function(e){
  var target = $(e.target);
  var exit = false;

  // if click inside popup stop
  if(target.is('[data-popup-container]') || target.parents('[data-popup-container]').length > 0) { exit = true; }

  // if click inside close button don't stop
  if(target.is('[data-popup-close]') || target.parents('[data-popup-close]').length > 0) { exit = false; }

  // if stop => don't hide modals
  if(exit) { return; } 

  // close modals
  popupClose(this);
});

// popup close
$('[data-popup-close]').on('click', function(){
  var popupEl = $(this).closest('[data-popup]');

  popupClose(popupEl);
});

export function popupShow(popup) {
  var popupEl = null;

  // if passed jquery object
  if(popup instanceof jQuery && popup.length > 0) popupEl = popup;
  // if passed js element
  else if(popup instanceof Element) popupEl = $(popup);
  // if passed string
  else popupEl = $('[data-popup="' + popup + '"]');

  if(popupEl.length) {
    // disable scroll
    $('body').css('overflow-y', 'hidden');

    popupEl.show();
  }
}

export function popupClose(popup) {
  var popupEl = null;

  // if passed jquery object
  if(popup instanceof jQuery && popup.length > 0) popupEl = popup;
  // if passed js element
  else if(popup instanceof Element) popupEl = $(popup);
  // if passed string
  else popupEl = $('[data-popup="' + popup + '"]');

  // remove active or loading
  popupEl.hide();
  popupEl.removeClass('popup--success');
  popupEl.removeClass('popup--loading');

  // enable scroll
  $('body').css('overflow-y', 'auto');
}
