let cartButtonEl = $('.header-cart');
let cartEl 			 = $('.header-cart-popup');

// show cart
cartButtonEl.on('click', function(){
	// hide
	if(cartButtonEl.is('.active')){
		cartButtonEl.removeClass('active');
		cartEl.hide();
	}
	// show
	else {
		cartButtonEl.addClass('active');		
		cartEl.fadeIn();
	}	
});

let closeBtn = $('.header-cart-popup__close');
cartEl.on('click', '.header-cart-popup__close', function(){
	cartEl.fadeOut();
});

// hide cart
$('body').on('click', function(e){
	var targetEl = $(e.target);

	// if click inside cart or cartbutton return 
	if(targetEl.is(cartEl) || targetEl.closest(cartEl).length || targetEl.is(cartButtonEl) || targetEl.closest(cartButtonEl).length) return;
	// hide cart
	cartButtonEl.removeClass('active');
	cartEl.hide();
});
