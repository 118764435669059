import '@fancyapps/fancybox';

// gallery zoom
let galleryBtnEls = document.querySelectorAll('.images-carousel [data-zoom-gallery]');

galleryBtnEls.forEach((btn) => productImagesZoom(btn));

export default function productImagesZoom(btnEl){
  btnEl.addEventListener('click', function(e){
    e.preventDefault();
    
    // get gallery images
    let group    = btnEl.getAttribute('data-zoom-gallery');
    
    // to array
    galleryBtnEls = Array.from(galleryBtnEls);
    let groupEls = galleryBtnEls.filter((item) => item.getAttribute('data-zoom-gallery') == group)
      .map((item) => { return { src: item.getAttribute('href') } });
      
    // get index
    let index = 0;
    for(let i = 0; i < groupEls.length; i++) if(groupEls[i].src == btnEl.getAttribute('href')) index = i;
    
    // show fancybox
    let instance = $.fancybox.open(groupEls, {
      onInit: (instance) => {
        instance.jumpTo(index);
      }
    });
  });
}