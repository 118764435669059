import { removeItem } 		from './helpers/removeItem';
import { addItem } 				from './helpers/addItem';
import { changeAmount }   from './helpers/changeAmount';
import { renderChanges }  from './helpers/renderChanges';

// update on load for enabled cache
renderChanges();

// add and remove items from cart
let addBtnEls = $('[data-cart-add-btn]');
addBtnEls.on('click', function(){
	let thisEl = $(this);

	// remove from cart
	if(thisEl.is('.active')) {
		removeItem(this.getAttribute('data-product-id'));
	}
	// add to cart
	else {
		addItem(this.getAttribute('data-product-id'));
	}
});

// remove items from cart
$(document).on('click', '[data-cart-remove-btn]', function(){
	removeItem(this.getAttribute('data-product-id'));
});

// input filter
$('.cart').on('input', '[data-cart-amount]', function(e){
	e.stopImmediatePropagation();

	// if empty
	if(!this.value) return;

	// if non numeric
	let isNum = this.value[this.value.length - 1].match(/[0-9]/g);
	if(!isNum) this.value = this.value.substring(0, this.value.length - 1);

	// // if more than limit
	let max = +this.getAttribute('data-max') || 99;
	if(+this.value > max) this.value = this.value.substring(0, this.value.length - 1);
});

// change count
$('.cart').on('click', '[data-cart-minus]', function(e){
	e.stopImmediatePropagation();

	let thisEl  = $(this);
	let itemEl  = thisEl.closest('[data-cart-item]');
	let countEl = itemEl.find('[data-cart-amount]');
	let count   = +countEl.val();
	let itemId  = itemEl.attr('data-product-id');

	if(count > 1) count--;

	changeAmount(itemId, count);
});

$('.cart').on('click', '[data-cart-plus]', function(e){
	e.stopImmediatePropagation();

	let thisEl  = $(this);
	let itemEl  = thisEl.closest('[data-cart-item]');
	let countEl = itemEl.find('[data-cart-amount]');
	let count   = +countEl.val();
	let itemId  = itemEl.attr('data-product-id');
	
	if(count < 99) count++;

	changeAmount(itemId, count);
});