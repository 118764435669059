import initProductImages  from '../product/slider';
import productImagesZoom  from '../product/zoom';
import { initMasks }      from '../shared/mask';

let quickEls = $('.catalog-products-item__details > .btn');
quickEls.on('click', function(e){
  let thisEl        = $(this);
  let wrapperEl     = thisEl.closest('.catalog-products');
  let detailsEl     = thisEl.closest('.catalog-products-item__details');
  let sourceEl      = detailsEl.find('> div > div');
  let itemEl        = thisEl.closest('.catalog-products-item');
  let itemEls       = wrapperEl.find('.catalog-products-item');

  // firstly close details
  closeDetails(wrapperEl);

  // get last item into row
  let insertAfterEl = getLastRowItem(itemEl);
  let destinationEl     = document.createElement('div');
  destinationEl.classList.add('details');
  insertAfterEl.parentNode.insertBefore(destinationEl, insertAfterEl.nextSibling);
  destinationEl = $(destinationEl);

  // show details
  sourceEl.clone(true).appendTo(destinationEl);

  // hide this btn
  thisEl.animate({
    opacity: 0
  }, 50);

  // add class
  itemEls.removeClass('opened');
  itemEl.addClass('opened');
  wrapperEl.addClass('opened');

  // reinit sliders
  let imagesEl = destinationEl.find('.product-images');
  if(imagesEl.length > 0) initProductImages(imagesEl[0]);

  // reinit zoom
  let zoomEls = destinationEl.find('[data-zoom-gallery]');
  for(let i = 0; i < zoomEls.length; i++) {
    let zoomEl = zoomEls[i];
    productImagesZoom(zoomEl);
  }

  // reinit masks
  initMasks();

  // scroll to details
  $('html, body').animate({
    scrollTop: destinationEl.offset().top - 100
  }, 500);
});

let wrapperEl = $('.catalog-products');
wrapperEl.on('click', '.details__close', function(){
  let wrapperEl = $(this).closest('.catalog-products');
  closeDetails(wrapperEl);
});

export function closeDetails(wrapperEl){
  let detailsBtnEls   = wrapperEl.find('.catalog-products-item__details > .btn');
  let itemEls         = wrapperEl.find('.catalog-products-item');
  let detailsEl       = wrapperEl.find('.details');
  let scrollBefore    = window.scrollY;
  let detailsHeight;
  let detailsTop;

  if(detailsEl.length) {
    detailsHeight   = detailsEl.height();
    detailsTop      = detailsEl.offset().top;
  }

  // show button and hide this
  detailsBtnEls.animate({
    opacity: 1
  }, 50);
  detailsEl.html('');

  // fix scroll
  if(detailsEl.length && scrollBefore == window.scrollY) {
    if(detailsTop < window.scrollY) {
      $(window).scrollTop(window.scrollY - detailsHeight);
    }
  }

  // remove class
  itemEls.removeClass('opened');
  wrapperEl.removeClass('opened');
}

// get last item in the row
function getLastRowItem(itemEl){
  itemEl    = $(itemEl);
  let wrapperEl = itemEl.closest('.catalog-products');
  let itemEls   = wrapperEl.find('.catalog-products-item');

  // get row items count based on window width
  let width = window.innerWidth;
  let columnsCount = 4;
  if(width <= 992) columnsCount = 3;
  if(width <= 768) columnsCount = 2;
  if(width <= 480) columnsCount = 1; 
  

  // remove existing details
  wrapperEl.find('.details').remove();
  
  // get index of current element
  let index = itemEls.index(itemEl);
  let insertAfterEl = null;
  
  // how much to skip to get last row element
  let skip = (columnsCount - 1) - (index % columnsCount);
  
  // get insert after element
  if(itemEls.length - (index + skip) > 0) {
    insertAfterEl = itemEls[(index + skip)];
  }
  else insertAfterEl = itemEls[itemEls.length - 1];

  // return last row item
  return insertAfterEl;
}