import 'jquery-match-height';

let modalBtnEls = $('.map-delivery .delivery-methods-list .modal-link');

// on modal open
modalBtnEls.on('click', function(){
	let infoEls = $('.map-delivery-method__block-info');

	// make equal height info
	infoEls.matchHeight();
});