var titleEls = $('.map-delivery-info__title');

titleEls.on('click', function(){
	var thisEl    = $(this);
	var infoEl    = thisEl.closest('.map-delivery-info');
	var contentEl = infoEl.find('.map-delivery-info__content');

	thisEl.toggleClass('active');
	contentEl.toggle();
});

if ($(window).width() <= 768) {
	if ($('.map-delivery-info').length > 0) {
		$('.map-delivery-info').prependTo($('.wrapper-map-blocks'));
	} 
}