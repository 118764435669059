import Swiper from 'swiper';
import 'jquery-match-height';
import { closeDetails } from './details';

let sliderEls = $('.gifts__slider');

for(var i = 0; i < sliderEls.length; i++) {
	let sliderEl 	= $(sliderEls[i]);
	let wrapperEl = sliderEl.closest('.gifts');
	let swiperEl  = wrapperEl.find('.swiper-container');
	let prevEl 		= wrapperEl.find('.swiper-button-prev');
	let nextEl 		= wrapperEl.find('.swiper-button-next');

	new Swiper(swiperEl[0], {
		slidesPerView: 3,
		navigation: {
    	prevEl: prevEl[prevEl.length - 1],
			nextEl: nextEl[nextEl.length - 1]
		},
		breakpoints: {
			992: {
				slidesPerView: 2
			},
			600: {
				slidesPerView: 1
			}
		},
		on: {
			init() {
				let titleEls = wrapperEl.find('.gifts-item__title');

				// make equal height
				titleEls.matchHeight();
			},
			
			slideChange(){
				closeDetails(wrapperEl);
			}
		}
	});
}