var CatalogSort = 
{
	$button: null,
	onLinkClick: function(event)
	{
		let $link = $(this);
		if($link.hasClass('current'))
			event.preventDefault();
	},
	onButtonClick: function(event)
	{
		$(this).closest('.catalog-sort_select').find('.js-catalog-sort-list').toggleClass('active');
	},
	onDocumentClick: function(event)
	{
		let sort = $('.catalog-sort_select');
        if (!sort.is(event.target) && sort.has(event.target).length === 0)
           	$('.js-catalog-sort-list').removeClass('active');
	},
	init: function()
	{
		this.$button = $('.js-catalog-sort');
		this.$button.click(this.onButtonClick);
		$(document).click(this.onDocumentClick);

		let $link = $('.js-catalog-sort-link');
		$link.click(this.onLinkClick);
	}
};

CatalogSort.init();