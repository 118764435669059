let expandEls = document.querySelectorAll('.map-delivery-pickups__mobile');

expandEls.forEach(itemEl => {
	itemEl.addEventListener('click', () => {
		let blockEl = itemEl.closest('.map-delivery-pickups__block');
		let itemsEl = blockEl.querySelector('.map-delivery-pickups__items');

		blockEl.classList.toggle('openned');
		$(itemsEl).slideToggle();
	});
});