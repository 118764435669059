// go to city block on click
window.highlightPointsCity = function(id) {
  let blockEls = $('.map-delivery-pickups__block');
  let blockEl = blockEls.filter('[data-id=' + id + ']');
  let scrollBlockEl = blockEls.closest('.popup__scroll');

  if(blockEl.length) {
	  // make block active
	  blockEls.removeClass('active');
	  blockEl.addClass('active');

	  // scroll to block
	  scrollBlockEl.animate({
	  	scrollTop: blockEl.offset().top - blockEl.offsetParent().offset().top
	  }, 200);
  }
}

// go to city block on click
window.highlightDaysCity = function(id) {
  let rowEls = $('.map-delivery-days__row');
  let rowEl = rowEls.filter('[data-id=' + id + ']');
  let scrollBlockEl = rowEls.closest('.popup__scroll');

  if(rowEl.length) {
	  // make block active
	  rowEls.removeClass('active');
	  rowEl.addClass('active');

	  // scroll to block
	  scrollBlockEl.animate({
	  	scrollTop: rowEl.offset().top - rowEl.offsetParent().offset().top
	  }, 200);
  }
}
