var markerEls = $('.map-delivery-items__item');

markerEls.on('mouseenter', function () {
	let thisEl    = $(this);
	let detailsEl = thisEl.find('.details');
	let itemsEl   = thisEl.closest('.map-delivery-items');
	let wrapperEl = thisEl.closest('.map-delivery');

	// z-index for items
	itemsEl.addClass('active');

	// hide info
	let infoEl  = wrapperEl.find('.map-delivery-info__content');
	let titleEl = wrapperEl.find('.map-delivery-info__title');

	infoEl.hide();
	titleEl.removeClass('active');

	// details
  detailsEl.show();
});

markerEls.on('mouseleave', function () {
	let thisEl    = $(this);
	let detailsEl = thisEl.find('.details');
	let itemsEl   = thisEl.closest('.map-delivery-items');

	// z-index for items
	itemsEl.removeClass('active');

	// details
  detailsEl.hide();
});