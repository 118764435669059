import { popupOpen, popupClose } from '../shared/popup';

/* Change product order id */

$('[data-change-product]').on('click', function(){
  // find modal button
  var productId = $(this).attr('data-product-id');
  var productModalButtonEl = $('[data-product-id="' + productId + '"][data-popup-btn]');

  // set variant id to modal button
  productModalButtonEl.attr('data-variant-id', $(this).attr('data-variant-id'));
});

/* Change product order id END */


/* Modals */

// discount order
$('[data-popup-btn="discount"]').on('click', function(e) {
  var modalEl = $('[data-popup="discount"]');

  // set title
  modalEl.find('.popup__product').html(this.getAttribute('data-product_title'));

  // set product id
  modalEl.find('input[name="product-id"]').val(this.getAttribute('data-product-id'));

  // set product url
  modalEl.find('input[name="product-url"]').val(this.getAttribute('data-product_url'));

  // set product price
  modalEl.find('input[name="product-price"]').val(this.getAttribute('data-product_price'));

  // set product name
  modalEl.find('input[name="product-name"]').val(this.getAttribute('data-product_title'));
});

// product order
$('[data-popup-btn="order"]').on('click', function(e) {
  var modalEl = $('[data-popup="order"]');

  // set title
  modalEl.find('.popup__product').html($(this).attr('data-product_title'));

  // set product id
  modalEl.find('input[name="product-id"]').val($(this).attr('data-product-id'));

  // set product url
  modalEl.find('input[name="product-url"]').val($(this).attr('data-product_url'));

  // set product price
  modalEl.find('input[name="product-price"]').val($(this).attr('data-product_price'));
});

// product order complect
$('[data-popup-btn="complect"]').on('click', function(e) {
  var modalEl = $('[data-popup="complect"]');

  // set titles
  modalEl.find('.popup__product').html($(this).attr('data-product_title'));

  // set product id
  modalEl.find('input[name="product-id"]').val($(this).attr('data-product-id'));

  // set product url
  modalEl.find('input[name="product-url"]').val($(this).attr('data-product_url'));

  // set product price
  modalEl.find('input[name="product-price"]').val($(this).attr('data-product_price'));

  // set product name
  modalEl.find('input[name="product-name"]').val($(this).attr('data-product_name'));
});

/* Modals END */


/* Retail CRM */

// Order product
$('[data-retailcrm-ajax="order"]').on('submit', function(e){
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = formEl.closest('[data-popup]'); 
  popupEl.addClass('popup--loading');

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_order',
      name: $(this).find('input[name=name]').val(),
      phone: $(this).find('input[name=phone]').val(),
      email: $(this).find('input[name=email]').val(),
      product: $(this).find('input[name=product-id]').val(),
      price: $(this).find('input[name=product-price]').val(),
      quantity: 1,
      product_name: $(this).find('.popup__product').text()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formazakaztovara'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success response');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error:', errorMsg, errorObj);
      return;
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Order product complect
$('[data-retailcrm-ajax="complect"]').on('submit', function(e){
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = formEl.closest('[data-popup]'); 
  popupEl.addClass('popup--loading');

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_complect',
      name: $(this).find('input[name=name]').val(),
      phone: $(this).find('input[name=phone]').val(),
      email: $(this).find('input[name=email]').val(),
      product: $(this).find('input[name=product-id]').val(),
      price: $(this).find('input[name=product-price]').val(),
      quantity: 1,
      product_name: $(this).find('input[name="product-name"]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formazakazkomplekt'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success response');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error', errorMsg, errorObj);
      return;
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Call me
$('[data-retailcrm-ajax="callme"]').on('submit', function(e){
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = formEl.closest('[data-popup]'); 
  popupEl.addClass('popup--loading');

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_callme',
      name: $(this).find('input[name=name]').val(),
      phone: $(this).find('input[name=phone]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        // if error in index.php

        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formazakazzvonka'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success response');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error:', errorMsg, errorObj);
      return;
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Mount
$('[data-retailcrm-ajax="mount"]').on('submit', function(e){
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = formEl.closest('[data-popup]'); 
  popupEl.addClass('popup--loading');

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_mount',
      name: $(this).find('input[name=name]').val(),
      phone: $(this).find('input[name=phone]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        // if error in index.php

        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formaustanovka'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success response');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error:', errorMsg, errorObj);
      return;
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Bequick
//$('[data-retailcrm-ajax="bequick"]').on('submit', function(e){
$('body').on(
  'submit', 
  '[data-retailcrm-ajax="bequick"]', 
  function(e){
	  e.preventDefault();

	  // form element
	  var formEl = $(this);

	  // popup element
	  var popupEl = $('[data-popup="bequick"]'); 
	  popupEl.addClass('popup--loading');
	  popupEl.show();

	  // upload to crm
	  $.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		data: {
		  action: 'retailcrm_bequick',
		  phone: $(this).find('input[name=phone]').val()
		},
		success: function(result, status, xhr) {
		  if(status == 'success') {
			// if error in index.php

			if(result.success == 1) {
			  // show success modal
			  popupEl.removeClass('popup--loading');
			  popupEl.addClass('popup--success');

			  // Google tab manager event
			  dataLayer.push({'event': 'formauspei'});
			}
			else {
			  // close modal
			  popupClose(popupEl);

			  // show error message
			  alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			  console.log('Not success response');
			  return;
			}
		  }
		  else {
			// close modal
			popupClose(popupEl);

			// show error message
			alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			console.log('Not success status');
			return;
		  }
		},
		error: function (xhr, errorMsg, errorObj) {
		  // close modal
		  popupClose(popupEl);
		  
		  // show error message
		  alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
			  + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
		  console.log('Error:', errorMsg, errorObj);
		  return;
		}
	  });

	  return false; // отмена сработки стандартного механизма сабмита формы
	}
);

// Discount order
$('[data-retailcrm-ajax="discount"]').on('submit', function(e) {
  e.preventDefault();

  let formEl = $(this);

  // popup element
  let popupEl = formEl.closest('[data-popup]'); 
  popupEl.addClass('popup--loading');


  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_discount',
      product: $(this).find('input[name="product-id"]').val(),
      price: $(this).find('input[name="product-price"]').val(),
      product_name: $(this).find('input[name="product-name"]').val(),
      name: $(this).find('input[name=name]').val(),
      phone: $(this).find('input[name=phone]').val(),
      email: $(this).find('input[name=email]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formaaction'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success reponse');
          return;
        }
      }
      else {
        // action('bad response status', 'request-action-form: '+status);

        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error', errorMsg, errorObj);
      return;
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Consultation
//$('[data-retailcrm-ajax="consultation"]').on('submit', function(e) {
$('body').on(
  'submit', 
  '[data-retailcrm-ajax="consultation"]', 
  function(e) {
	  e.preventDefault();

	  // form element
	  var formEl = $(this);

	  // popup element
	  var popupEl = $('[data-popup="consultation"]'); 
	  popupEl.addClass('popup--loading');
	  popupEl.show();


	  // upload to crm
	  $.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		data: {
		  action: 'retailcrm_consultation',
		  name: $(this).find('input[name=name]').val(),
		  phone: $(this).find('input[name=phone]').val()
		},
		success: function(result, status, xhr) {
		  if(status == 'success') {
			if(result.success == 1) {
			  // show success modal
			  popupEl.removeClass('popup--loading');
			  popupEl.addClass('popup--success');

			  // Google tab manager event
			  dataLayer.push({'event': 'formakonsul1'});
			}
			else {
			  // close modal
			  popupClose(popupEl);

			  // show error message
			  alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			  console.log('Not success response');
			  return;
			}
		  }
		  else {
			// close modal
			popupClose(popupEl);

			// show error message
			alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			console.log('Not success status');
			return;
		  }
		},
		error: function (xhr, errorMsg, errorObj) {
		  // close modal
		  popupClose(popupEl);
		  
		  // show error message
		  alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
			  + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
		  console.log('Error', errorMsg, errorObj);
		  return;
		}
	  });

	  return false; // отмена сработки стандартного механизма сабмита формы
	}
);

// Review
//$('[data-retailcrm-ajax="review"]').on('submit', function(e) {
$('body').on(
  'submit', 
  '[data-retailcrm-ajax="review"]', 
  function(e){
	  e.preventDefault();

	  // form element
	  var formEl = $(this);

	  // popup element
	  var popupEl = $('[data-popup="review"]'); 
	  popupEl.addClass('popup--loading');
	  popupEl.show();

	  // make form data
	  var formData = new FormData(this);
	  formData.append('action', 'retailcrm_review');

	  // upload to crm
	  $.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		cache: false,
		processData: false, // Не обрабатываем файлы (Don't process the files)
		contentType: false, // Так jQuery скажет серверу что это строковой запрос
		data: formData,
		success: function(result, status, xhr) {
		  if(status == 'success') {
			if(result.success == 1) {
			  // show success modal
			  popupEl.removeClass('popup--loading');
			  popupEl.addClass('popup--success');

			  // Google tab manager event
			  dataLayer.push({'event': 'formaotziv'});
			}
			else {
			  // close modal
			  popupClose(popupEl);

			  // show error message
			  alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			  console.log('Not success response');
			  return;
			}
		  }
		  else {
			// close modal
			popupClose(popupEl);

			// show error message
			alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
						+ 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
			console.log('Not success status');
			return;
		  }
		},
		error: function (xhr, errorMsg, errorObj) {
		  // close modal
		  popupClose(popupEl);
		  
		  // show error message
		  alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
			  + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
		  console.log('Error:', errorMsg, errorObj);
		  return;
		}
	  });

	  return false; // отмена сработки стандартного механизма сабмита формы
	}
);

// Footer consultation
$('[data-retailcrm-ajax="footer"]').on('submit', function(e) {
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = $('[data-popup="footer"]'); 
  popupEl.addClass('popup--loading');
  popupEl.show();


  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_footer',
      phone: $(this).find('input[name=phone]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formakonsultfooter'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success reponse');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error', errorMsg, errorObj);
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});

// Product order
$('[data-retailcrm-ajax="product-order"]').on('submit', function(e) {
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = $('[data-popup="product-order"]'); 
  popupEl.addClass('popup--loading');
  popupEl.show();

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action:       'retailcrm_order',
      name:         formEl.find('input[name=name]').val(),
      phone:        formEl.find('input[name=phone]').val(),
      email:        formEl.find('input[name=email]').val(),
      product:      formEl.find('input[name=product-id]').val(),
      price:        formEl.find('input[name=product-price]').val(),
      quantity:     1,
      product_name: formEl.find('input[name=product-name]').val()
    }
  })
  .done((result, status) => {
    if(status == 'success') {
      if(result.success == 1) {
        // show success modal
        popupEl.removeClass('popup--loading');
        popupEl.addClass('popup--success');

        // Google tab manager event
        dataLayer.push({'event': 'formaproductorder'});
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                  + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success reponse');
        return;
      }
    }
    else {
      // close modal
      popupClose(popupEl);

      // show error message
      alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                  + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
      console.log('Not success status');
      return;
    }
  })
  .fail((xhr, errorMsg, errorObj) => {
    // close modal
    popupClose(popupEl);
      
    // show error message
    alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
        + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
    console.log('Error', errorMsg, errorObj);
  });
});

// Cart order
$('[data-retailcrm-ajax="cart"]').on('submit', function(e){
  e.preventDefault();

  // form element
  var formEl = $(this);

  // popup element
  var popupEl = $('[data-popup="cart"]'); 
  popupEl.addClass('popup--loading');
  popupEl.show();

  // upload to crm
  $.ajax({
    url: window.data.ajax_url,
    type: 'post',
    dataType: 'json',
    data: {
      action: 'retailcrm_cart',
      phone: formEl.find('input[name=phone]').val(),
      email: formEl.find('input[name=email]').val(),
      name: formEl.find('input[name=name]').val()
    },
    success: function(result, status, xhr) {
      if(status == 'success') {
        if(result.success == 1) {
          // show success modal
          popupEl.removeClass('popup--loading');
          popupEl.addClass('popup--success');

          // Google tab manager event
          dataLayer.push({'event': 'formakart'});
        }
        else {
          // close modal
          popupClose(popupEl);

          // show error message
          alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
          console.log('Not success reponse');
          return;
        }
      }
      else {
        // close modal
        popupClose(popupEl);

        // show error message
        alert('&nbsp;<br>К сожалению, возникла техническая проблема при передаче данных оператору.<br>'
                    + 'Попробуйте повторить запрос позже, либо свяжитесь с оператором по указанному на сайте номеру телефона.<br>&nbsp;');
        console.log('Not success status');
        return;
      }
    },
    error: function (xhr, errorMsg, errorObj) {
      // close modal
      popupClose(popupEl);
      
      // show error message
      alert('&nbsp;<br>К сожалению, возникла проблема при передаче данных.<br>'
          + 'Сервер сообщает: <i>'+errorObj+'</i><br>&nbsp;');
      console.log('Error', errorMsg, errorObj);
    }
  });

  return false; // отмена сработки стандартного механизма сабмита формы
});


/* Retail CRM END */