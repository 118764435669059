import Swiper from 'swiper';

let sliderEls = $('.block-products__slider');

for(var i = 0; i < sliderEls.length; i++) {
	let sliderEl 	= $(sliderEls[i]);
	let wrapperEl = sliderEl.closest('.block-products');
	let swiperEl  = wrapperEl.find('.swiper-container');
	let prevEl 		= wrapperEl.find('.swiper-button-prev');
	let nextEl 		= wrapperEl.find('.swiper-button-next');

	new Swiper(swiperEl[0], {
		slidesPerView: 4,
		navigation: {
    	prevEl: prevEl[0],
			nextEl: nextEl[0]
		},
		breakpoints: {
			992: {
				slidesPerView: 2
			},
			650: {
				slidesPerView: 1
			}
		}
	});
}