// menus and menu button
let menuBtnEls    = $('[data-mobile-menu-open]');
let menuCloseEls = $('[data-mobile-menu-close]');

// show menu
menuBtnEls.on('click', function(){
	let thisEl = $(this);
	let menuEl = $('[data-mobile-menu=' + thisEl.attr('data-mobile-menu-open') + ']');

	menuEl.addClass('active');

	// disable scroll
	if(menuEl) {
		$('body').css('overflow-y', 'hidden');
	}
});

// close menu
menuCloseEls.on('click', function(){
	let menuEl = $(this).closest('[data-mobile-menu]');
	
	menuEl.removeClass('active');
	
	// if there are no active mobile menus
	// disable scroll
	let activeMobileMenuEls = $('[data-mobile-menu].active');
	if(!activeMobileMenuEls.length) $('body').css('overflow-y', '');
});


$(document).ready(function () {
	 // мобильное меню

    $('.js-btn-m-menu').click(function () {
        $('.js-mobile-menu').addClass('active');
        $('body').addClass('fixed');
    });

    $('.js-close-mob-menu').click(function () {
        $('.js-mobile-menu').removeClass('active');
        $('body').removeClass('fixed');
    });

    $('.js-mob-menu').click(function (e) {
        e.preventDefault();

        if (!$(this).closest('.menu-mobile__item').hasClass('active')) {
            $('.menu-mobile__sub-menu').fadeOut(100);
            $('.menu-mobile__item').removeClass('active');
            $(this).closest('.menu-mobile__item').addClass('active');

            $(this).closest('.menu-mobile__item').find('.menu-mobile__sub-menu').fadeIn(100);
        } else {
            $('.menu-mobile__item').removeClass('active');
            $('.menu-mobile__sub-menu').fadeOut(200);
        }
    })

    $('.js-bnt-accessories').click(function (e) {
        e.preventDefault();
        if (!$(this).closest('.js-sub-accessories').hasClass('active')) {

            $(this).closest('ul').children('.js-sub-accessories').removeClass('active');
            $(this).closest('ul').find('.sub-accessories_ul').fadeOut(1);

            if ($(this).closest('ul').hasClass('menu-mobile__submenu')) {
                $('.sub-accessories_ul').fadeOut(1);
                $('.js-sub-accessories').removeClass('active');
            }

            $(this).closest('.js-sub-accessories').addClass('active');
            $(this).closest('.js-sub-accessories').children('.sub-accessories_ul').fadeIn(1);

        } else {

            if ($(this).closest('ul').hasClass('menu-mobile__submenu')) {
                $('.sub-accessories_ul').fadeOut(1);
                $('.js-sub-accessories').removeClass('active');
            }

            $(this).closest('ul').children('.js-sub-accessories').removeClass('active');
            $(this).closest('ul').find('.sub-accessories_ul').fadeOut(1);
        }
    });


    $('.js-answers-btn').click(function () {
        if (!$(this).closest('.js-answers-item').hasClass('active')) {
            $(this).closest('.js-answers-item').addClass('active');
            $(this).closest('.js-answers-item').find('.js-answers-text').slideDown(200);
        } else {
            $(this).closest('.js-answers-item').removeClass('active');
            $(this).closest('.js-answers-item').find('.js-answers-text').slideUp(200);
        }
    });

    $('.aksessuary-menu__link').click(function (e) {
        e.preventDefault();

        if (!$(this).closest('.js-aksessuary-menu').hasClass('active')) {
            $('.aksessuary-menu__sub-menu').slideUp(200);
            $('.js-aksessuary-menu').removeClass('active');

            $(this).closest('.js-aksessuary-menu').addClass('active');
            $(this).closest('.js-aksessuary-menu').find('.aksessuary-menu__sub-menu').slideDown(200);
        } else {
            $('.js-aksessuary-menu').removeClass('active');
            $('.aksessuary-menu__sub-menu').slideUp(200);
        }
    });

    $('.menu-dop-info_close').click(function () {

        $(this).closest('.menu-dop-info').addClass('none');

        let d = new Date();
        d.setTime(d.getTime() + 86400000);
        let expires = "expires=" + d.toUTCString();

        document.cookie = 'stenka_header_popup=1' + ";" + expires + ";path=/";
    });
    
});