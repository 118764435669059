import initProductImages  from '../product/slider';
import productImagesZoom  from '../product/zoom';
import { initMasks }      from '../shared/mask';

let quickEls = $('.gifts-item__details');

quickEls.find('> .btn').on('click', function(e){
  let thisEl        = $(this);
  let wrapperEl     = thisEl.closest('.gifts');
  let detailsEl     = thisEl.closest('.gifts-item__details');
  let sourceEl      = detailsEl.find('> div > div');
  let destinationEl = wrapperEl.find('.details');
  let itemEl        = thisEl.closest('.gifts-item');
  let itemEls       = wrapperEl.find('.gifts-item');

  // firstly close details
  closeDetails(wrapperEl);

  // if in cart make active
  destinationEl.attr('data-product-id', itemEl.attr('data-product-id'));
  if(itemEl.is('.active')) destinationEl.addClass('active');
  else destinationEl.removeClass('active');

  // append details
  sourceEl.clone(true).appendTo(destinationEl);

  // hide this btn
  thisEl.animate({
    opacity: 0
  }, 50);

  // add class
  itemEls.removeClass('opened');
  itemEl.addClass('opened');
  wrapperEl.addClass('opened');

  // reinit sliders
  let imagesEl = destinationEl.find('.product-images');
  if(imagesEl.length > 0) initProductImages(imagesEl[0]);

  // reinit zoom
  let zoomEls = destinationEl.find('[data-zoom-gallery]');
  for(let i = 0; i < zoomEls.length; i++) {
    let zoomEl = zoomEls[i];
    productImagesZoom(zoomEl);
  }

  // reinit masks
  initMasks();

  // scroll to details
  $('html, body').animate({
    scrollTop: destinationEl.offset().top - 100
  }, 500);
});

let wrapperEl = $('.gifts');
wrapperEl.on('click', '.details__close', function(){
  let wrapperEl = $(this).closest('.gifts');
  closeDetails(wrapperEl);
});

export function closeDetails(wrapperEl){
  let detailsBtnEls   = wrapperEl.find('.gifts-item__details > .btn');
  let itemEls         = wrapperEl.find('.gifts-item');
  let detailsEl       = wrapperEl.find('.details');
  let scrollBefore    = window.scrollY;
  let detailsHeight;
  let detailsTop;

  if(detailsEl.length) {
    detailsHeight   = detailsEl.height();
    detailsTop      = detailsEl.offset().top;
  }

  // show button and hide this
  detailsBtnEls.animate({
    opacity: 1
  }, 50);
  detailsEl.html('');

  // fix scroll
  if(detailsEl.length && scrollBefore == window.scrollY) {
    if(detailsTop < window.scrollY) {
      $(window).scrollTop(window.scrollY - detailsHeight);
    }
  }

  // remove class
  itemEls.removeClass('opened');
  wrapperEl.removeClass('opened');
}