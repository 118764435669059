import { renderChanges } from './renderChanges.js';
import { popupOpen, popupClose } from '../../shared/popup';

export function addItem(productId){
	// add loader
	$('.cart-table').addClass('cart-table--loading');
	$('.header-cart-popup').addClass('header-cart-popup--loading');

	// add item
	$.ajax({
		url: window.data.ajax_url,
		type: 'post',
		dataType: 'json',
		data: {
			action: 'cart_add_product',
			product_id: productId
		}
	})
	.done(function(response){
		// if error
		if(response.error) {
			if(response.errorType == 'gift-limit') {
				// popup element
				let popupEl = $('[data-popup="gift-limit"]'); 
				popupEl.addClass('popup--success');
				popupEl.show();
			}
			else alert(response.error);
			
			renderChanges();
		}
		// update cart header
		else if(response.success) {
			renderChanges();

			// update buttons
			let buttonEls = $('[data-cart-add-btn][data-product-id="' + productId + '"]');
			buttonEls.addClass('active');

			// update gift items
			let giftEls = $('[data-gift][data-product-id="' + productId + '"]');
			giftEls.addClass('active');

			// update gift details
			let giftDetailsEl = $('[data-gift-details][data-product-id="' + productId + '"]');
			giftDetailsEl.addClass('active');
		}
	})
	.fail(function(error){
		console.error(error);
		alert('Непредвиденная ошибка');
	});
}