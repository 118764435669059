import AOS from 'aos';

document.addEventListener('aos:in:map-delivery', function(details){
	let mapEl 		= $(details.detail);
	let markerEls = mapEl.find('.map-delivery-items__item');
	let biggestDurationDelay = 0;

	for(let i = 0; i < markerEls.length; i++) {
		(function(){
			let markerEl 					= $(markerEls[i]);
			let animationDelay 		= parseFloat(markerEl.css('animation-delay'));
			let animationDuration = parseFloat(markerEl.css('animation-duration'));
			if(biggestDurationDelay < animationDelay + animationDuration) biggestDurationDelay = animationDelay + animationDuration;

			// make marker visible
			setTimeout(function(){
				markerEl.css('opacity', 1);

				// show title
				markerEl.find('.marker-title').show();
			}, animationDelay * 1000 + 300);
		})();
	}

	// hide all titles after animation end
	setTimeout(function(){
		markerEls.find('.marker-title').removeAttr('style');
	}, biggestDurationDelay * 1000); 
});