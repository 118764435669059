import Swiper from 'swiper';

var reviewsEls = $('.reviews');

export function initializeReviewsSliders(){
	for(var i = 0; i < reviewsEls.length; i++) {
		var reviewsEl  = $(reviewsEls[i]);
		var sliderEls  = reviewsEl.find('.reviews__slider');
	
		for(var y = 0; y < sliderEls.length; y++) {
			(function(){
				var sliderEl  = $(sliderEls[y]);
				var swiperEl  = sliderEl.find('.swiper-container');
				var wrapperEl = sliderEl.closest('.reviews'); 
				var blockEl   = sliderEl.closest('.reviews__panel');
	
				if(swiperEl[0]) {
					var swiper = new Swiper(swiperEl[0], {
						slidesPerView: 3,
						simulateTouch: false,
						spaceBetween: 30,
						centeredSlides: true,
						loop: true,
						navigation: {
							prevEl: sliderEl.find('.swiper-button-prev')[0],
							nextEl: sliderEl.find('.swiper-button-next')[0]
						},
						on: {
							slideChangeTransitionEnd: function(){
								// pause slider videos on slidechange
								sliderEl.find('video, audio').trigger('pause');
	
								// change content linked to slide
								var content = sliderEl.find('.swiper-slide-active [data-review-content]').html();
								var contentEl = blockEl.find('.reviews__content'); 
	
								sliderEl.find('.swiper-slide-active');
	
								if(content) contentEl.show();
								else contentEl.hide();
	
								contentEl.html(content);
							}
						},
						breakpoints: {
							768: {
								slidesPerView: 1
							}
						}
					});
				}
			})();
		} 
	}
}

initializeReviewsSliders();