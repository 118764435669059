

function scroll_to(e)
{
	e.preventDefault();

	let thisEl     = $(this);
	let targetEl   = $(thisEl.attr('data-scroll-to'));
	let scrollTime = thisEl.attr('data-scroll-time') || 300;

	if(targetEl.length > 0) {
		$('body, html').animate({
			scrollTop: targetEl.offset().top - 20
		}, scrollTime);
		$('.js-mobile-menu').removeClass('active');
		$('body').removeClass('fixed');
	}

	return false;
}

$('[data-scroll-to]').on('click', scroll_to);

$('.free-delivery').on('click', '[data-scroll-to]', scroll_to);