var HeaderSearch = 
{
	$button: null,
	onButtonClick: function(event)
	{
		$(this).closest('.header-search').find('.header-search_list').toggleClass('active');
	},
	onDocumentClick: function(event)
	{
		let search = $('.header-search');
        if (!search.is(event.target) && search.has(event.target).length === 0)
           	$('.header-search_list').removeClass('active');
	},
	init: function()
	{
		this.$button = $('.js-header-search');
		this.$button.click(this.onButtonClick);
		$(document).click(this.onDocumentClick);
	}
};

HeaderSearch.init();